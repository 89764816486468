import { datadogLogs } from "@datadog/browser-logs";

datadogLogs.init({
    clientToken: "pub6772680188c7b1af05878856da4f89b2",
    site: "datadoghq.com",
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    env: process.env.REACT_APP_ENVIRONMENT,
    beforeSend: () => {
        // Do not monitor local dev env
        return window.location.hostname !== "localhost";
    },
});

datadogLogs.logger.info("Tymeline seesion started");

export const setUser = (user) =>
    datadogLogs.setUser({
        id: user.id,
        name: `${user.firstName} ${user.lastName}`,
    });

export default datadogLogs.logger;
