import React from "react";

import { Box, Typography } from "components/toolkit";
import { blue, green, grey, orange } from "@mui/material/colors";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const NEW_STATUS = "New";
const IN_PROGRESS_STATUS = "In Progress";
const COMPLETED_STATUS = "Completed";
const READY_STATUS = "Ready";
const EPICS_STATUS = "Epics Generating"

export const StatusPill = ({ status, variant = "outlined" }) => {
    const colorMap = {
        [IN_PROGRESS_STATUS]: orange[700],
        [NEW_STATUS]: blue[400],
        [COMPLETED_STATUS]: green[400],
        [READY_STATUS]: green[500],
        [EPICS_STATUS]: blue[900]
    };
    const color = colorMap[status];
    return variant === "outlined" ? (
        <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            component='span'
            gap={0.5}
            sx={{
                py: 0.5,
                px: 1,
                height: "1.5rem",
                minWidth: "2.5rem",
                // maxWidth: "8rem",
                borderRadius: 4,
                border: "1px solid",
                color: `${color} !important`,
            }}>
            <Typography variant='caption' style={{ color: "inherit" }}>
                {status}
            </Typography>
            {status === COMPLETED_STATUS && <CheckCircleIcon color='success' />}
        </Box>
    ) : variant === "contained" ? (
        <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            component='span'
            gap={0.5}
            sx={{
                py: 0.5,
                px: 2,
                height: "1.5rem",
                minWidth: "2.5rem",
                // maxWidth: "8rem",
                borderRadius: "1rem",
                // border: "1px solid",
                // color: `${color} !important`,
                backgroundColor: grey[300],
            }}>
            <Typography variant='caption' textColor='text'>
                {status}
            </Typography>
            {status === COMPLETED_STATUS && <CheckCircleIcon color='success' />}
        </Box>
    ) : (
        <Typography variant='caption' textColor='text'>
            {status}
        </Typography>
    );
};

export default StatusPill;
