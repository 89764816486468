import Office from "components/toolkit/Icons/Office";
import Shop from "components/toolkit/Icons/Shop";
import React from "react";
import rookieRoutesConfig from "./rookieRoutesConfig";
import vacancyRoutesConfig from "./vacancyRoutesConfig";
import tymelineRoutesConfig from "./tymelineRoutesConfig";
import HelpIcon from "@mui/icons-material/Help";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import GavelIcon from "@mui/icons-material/Gavel";
import AddCardIcon from "@mui/icons-material/AddCard";
import Home from "components/toolkit/Icons/Home";
import Goals from "components/toolkit/Icons/Goals";
import Projects from "components/toolkit/Icons/Projects";

// import DashboardIcon from "@mui/icons-material/Dashboard";
import FlagIcon from "@mui/icons-material/Flag";
import { MdFace, MdSupervisorAccount } from "react-icons/md";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import Diversity2Icon from "@mui/icons-material/Diversity2";
import Diversity1Icon from "@mui/icons-material/Diversity1";
import ViewKanbanIcon from "@mui/icons-material/ViewKanban";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import { MEMBER } from "utils/constants";
import { ADMIN } from "utils/constants";
const AddTymelineEventComponent = React.lazy(
    () => import("pages/Tymeline/AddTymelineEventComponent")
);
const TalentDashboardPage = React.lazy(
    () => import("pages/talent/DashboardNew")
);
const DashboardPage = React.lazy(() => import("pages/dashboard"));
// const AdminDashboard = React.lazy(() => import("pages/adminDashboard"));
const ProfilePage = React.lazy(() => import("pages/new-profile-page"));
const CompanyPage = React.lazy(() => import("pages/CompanyPage"));
const RolesPage = React.lazy(() => import("pages/RolesPage"));
const TeamsPage = React.lazy(() => import("pages/TeamsPage"));
const TeamsSyncPage = React.lazy(() => import("pages/org-members-section"));
const OrganizationGoalsPage = React.lazy(
    () => import("pages/Organizational Goals/OrganizationGoals")
);
const TeamMemberGoalsPage = React.lazy(
    () => import("pages/TeamMemberGoals/TeamMemberGoals")
);
const TeamGoalsPage = React.lazy(() => import("pages/Goals/TeamGoals"));
const PeopleSearchPage = React.lazy(() => import("pages/peopleSearch"));
const ProjectsPage = React.lazy(() => import("pages/projects"));
const ViewProjectTemplatesPage = React.lazy(
    () => import("pages/projects/templates/ViewTemplatesPage")
);
export const DASHBOARD = "User Dashboard";
export const ADMIN_DASHBOARD = "Admin Dashboard";
const routes = [
    {
        path: "/user-dashboard",
        component: DashboardPage,
        className: "tymeline_dashboard",
        exact: true,
        type: "collapse",
        name: DASHBOARD,
        icon: <Shop size='12px' />,
        noCollapse: true,
        showInMenu: true,
        noSuperAdmin: true,
        role: [MEMBER, ADMIN],
    },
    // {
    //     path: "/admin-dashboard",
    //     component: AdminDashboard,
    //     className: "tymeline_dashboard",
    //     exact: true,
    //     type: "collapse",
    //     name: ADMIN_DASHBOARD,
    //     icon: <Shop size='12px' />,
    //     noCollapse: true,
    //     showInMenu: true,
    //     noSuperAdmin: false,
    //     role: [ADMIN],
    // },
    {
        type: "collapse",
        name: "Project Hub",
        icon: <ViewKanbanIcon size='12px' />,
        keyName: "projects",
        exact: true,
        showInMenu: true,
        noSuperAdmin: false,
        accessKey: "peopleHub",
        role: [MEMBER, ADMIN],
        collapse: [
            {
                path: "/projects/view-all-projects",
                name: "Projects",
                key: "view-all-projects",
                component: ProjectsPage,
                icon: <AccountTreeIcon size='12px' />,
                exact: true,
                role: [MEMBER, ADMIN],
            },
            {
                key: "epics",
                adderOnly: true,
                path: "/projects/epics",
                type: "collapse",
                name: "Epics",
                icon: <Diversity3Icon size='12px' />,
                component: OrganizationGoalsPage,
                className: "nominate_candidate",
                exact: true,
                role: [ADMIN],
            },
            {
                key: "user-stories",
                adderOnly: true,
                path: "/projects/user-stories/",
                type: "collapse",
                name: "User Stories",
                icon: <Diversity1Icon size='12px' />,
                component: TeamGoalsPage,
                className: "nominate_candidate",
                exact: true,
                role: [MEMBER, ADMIN],
            },
            {
                adderOnly: true,
                path: "/projects/milestones",
                key: "milestones",
                type: "collapse",
                name: "Milestones",
                icon: <Diversity2Icon size='12px' />,
                component: TeamMemberGoalsPage,
                className: "nominate_candidate",
                exact: true,
                role: [MEMBER, ADMIN],
            },
        ],
    },
    {
        type: "collapse",
        name: "Talent Hub",
        className: "talent-tymeline",
        icon: <MdSupervisorAccount size='12px' />,
        keyName: "talent",
        exact: true,
        showInMenu: true,
        noSuperAdmin: false,
        accessKey: "peopleHub",
        role: [ADMIN],
        collapse: [
            {
                path: "/talent/dashboard",
                name: "Talent Dashboard",
                key: "dashboard",
                component: TalentDashboardPage,
                icon: <DashboardRoundedIcon size='12px' />,
                exact: true,
                role: [ADMIN],
            },
            {
                path: "/talent/talent-search",
                name: "Talent Search",
                key: "talent-search",
                component: PeopleSearchPage,
                icon: <PersonSearchIcon size='12px' />,
                exact: true,
                role: [ADMIN],
            },
            {
                name: "Add Record",
                key: "add-event",
                className: "add_record",
                path: "/talent/add-event",
                component: AddTymelineEventComponent,
                icon: <AddCardIcon />,
                exact: true,
                adderOnly: true,
                role: [ADMIN],
            },
            {
                key: "teams",
                adderOnly: true,
                path: "/talent/teams",
                type: "collapse",
                name: "Teams",
                icon: <Diversity3Icon size='12px' />,
                component: TeamsPage,
                className: "nominate_candidate",
                exact: true,
                role: [ADMIN],
            },
            {
                key: "team-members",
                adderOnly: true,
                path: "/talent/team-members",
                type: "collapse",
                name: "Team Members",
                icon: <MdSupervisorAccount size='12px' />,
                component: TeamsSyncPage,
                className: "nominate_candidate",
                exact: true,
                role: [ADMIN],
            },
            {
                path: "/talent/roles",
                key: "roles",
                name: "Roles",
                component: RolesPage,
                icon: <MdFace />,
                exact: true,
                role: [ADMIN],
            },
        ],
    },
    {
        path: "/profilePage",
        component: ProfilePage,
        className: "tymeline_profile",
        exact: true,
        type: "collapse",
        name: "Profile",
        icon: <Shop size='12px' />,
        noCollapse: true,
        showInMenu: false,
        noSuperAdmin: true,
        role: ["default"],
    },
    {
        path: "/company-details",
        type: "collapse",
        name: "My Company",
        keyName: "company-details",
        icon: <Office size='12px' />,
        component: CompanyPage,
        noCollapse: true,
        // exact: true,
        showInMenu: true,
        noSuperAdmin: false,
        role: [ADMIN],
    },
    {
        type: "divider",
        key: "divider",
        showInMenu: true,
    },
    {
        href: "https://discord.gg/tyNxzgWTPK",
        type: "collapse",
        name: "Support",
        keyName: "support",
        icon: <HelpIcon size='12px' />,
        showInMenu: true,
        noSuperAdmin: true,
        noCollapse: true,
        role: ["default"],
    },
];
const finalRoutes = routes.concat(
    rookieRoutesConfig,
    vacancyRoutesConfig,
    tymelineRoutesConfig
);

export const getPageName = (route) => {
    return getRouteConfigByBasePath(route[0])?.name;
};

export const getRouteConfigByBasePath = (basePathName) => {
    return routes.find(({ keyName }) => keyName === basePathName);
};

export const getAuthConfig = (routeName) => {
    return routes.find(({ name }) => name === routeName);
};
export default finalRoutes;
