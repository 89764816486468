export let TYMELINE_BACKEND_API_ENDPOINT =
    process.env.REACT_APP_TYMELINE_BACKEND_API_ENDPOINT;
export let LINKEDIN_API_ENDPOINT = process.env.REACT_APP_LINKEDIN_API_ENDPOINT;
export let TYMELINE_TOOLS_ENDPOINT =
    process.env.REACT_APP_TYMELINE_TOOLS_ENDPOINT;

export let API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

if (process.env.NODE_ENV === "development") {
    API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
}

export const api_requests = {
    account: `${API_ENDPOINT}accm/login/`,
    account_tl: `${API_ENDPOINT}tl/account/login`,
    getOTP: `${API_ENDPOINT}tl/account/generate-otp`,
    getConsent: `${API_ENDPOINT}tl/consent/`,
    forgot_password: `${API_ENDPOINT}accm/reset_password_link/`,
    reset_password: `${API_ENDPOINT}accm/reset_password/`,
    masterLogin: `${API_ENDPOINT}tl/master/login`,
    user: `${API_ENDPOINT}dbu/user/`,
    newUser: `${API_ENDPOINT}external/dbu/create_user/from_invite_link`,
    generateLink: `${API_ENDPOINT}cd/invite_users/generate_link`,
    deleteLink: `${API_ENDPOINT}cd/invite_users/remove_link`,
    userInvite: `${API_ENDPOINT}dbu/userInvite/`,
    user_account: `${API_ENDPOINT}dbu/user/account/`,
    userList: `${API_ENDPOINT}dbu/userlist`,

    role: `${API_ENDPOINT}rc/role/`,
    roleList: `${API_ENDPOINT}rc/rolelist`,

    company: `${API_ENDPOINT}cd/company/`,
    companybasic: `${API_ENDPOINT}cd/company/basic/`,

    associatedCompanyList: `${API_ENDPOINT}tl/{id}/get-business`,

    onboard: `${API_ENDPOINT}hmn/human/create/`,
    onboardList: `${API_ENDPOINT}hmn/humanlist/`,
    onboardDetails: `${API_ENDPOINT}hmn/human/`,
    onboardAccount: `${API_ENDPOINT}hmndt/human-details/`,
    onboardDelete: `${API_ENDPOINT}hmn/human/`,
    multipleOnboardsCreate: `${API_ENDPOINT}hmn/human/create-list/`,
    multipleDateTimeNodeupdate: `${API_ENDPOINT}hmndt/human-details-admin-update-bulk/`,
    singleDateTimeNodeUpdate: `${API_ENDPOINT}hmndt/human-details-admin-update/`,
    onboardDetailsAdmin: `${API_ENDPOINT}hmn/human-admin/`,
    onboardAccountAdmin: `${API_ENDPOINT}hmndt/human-details-admin/`,
    orientation: `${API_ENDPOINT}oc/orientation/`,
    orientationLists: `${API_ENDPOINT}oc/orientationlist/`,

    training: `${API_ENDPOINT}tc/training/`,
    trainingList: `${API_ENDPOINT}tc/traininglist/`,

    subMenu: `${API_ENDPOINT}sbm/sub-menu/`,
    subMenuList: `${API_ENDPOINT}sbm/submenulist/`,

    item: `${API_ENDPOINT}tc/item/`,
    itemList: `${API_ENDPOINT}tc/itemlist`,

    question: `${API_ENDPOINT}qc/question/`,
    courseQuestion: `${API_ENDPOINT}lp/question/`,
    questionList: `${API_ENDPOINT}qc/questionlist/`,

    offerLetter: `${API_ENDPOINT}oflt/offer-letter/`,

    workflowList: `${API_ENDPOINT}wkfl/workflowlist/`,
    generalWorkflowList: `${API_ENDPOINT}general/workflowlist/`,
    workflow: `${API_ENDPOINT}wkfl/workflow/`,

    search: `${API_ENDPOINT}sbm/search/`,
    skillsList: `${API_ENDPOINT}skl/skill-list/`,
    skills: `${API_ENDPOINT}skl/skill/`,
    reports: `${API_ENDPOINT}rpt/reports/`,
    // teamsList: `${API_ENDPOINT}tm/teamlist/`,
    teamsList: `${API_ENDPOINT}tm/teamlist`,
    teamTitle: `${API_ENDPOINT}tm/team`,
    peopleTeamCreate: `${API_ENDPOINT}tm/people_search/team`,
    addTeamMembers: `${API_ENDPOINT}tm/addteamusers/`,
    // team: `${API_ENDPOINT}tm/team/`,
    // teamTitle: `${API_ENDPOINT}/team/${teamId}`,
    // vacancy: `${API_ENDPOINT}jp/vacancy`,
    accept_invite: `${API_ENDPOINT}tm/user/validateandaccept`,
    remove_teamUser: `${API_ENDPOINT}tm/team/removeorleaveteam/`,
    organizationGoal: `${TYMELINE_TOOLS_ENDPOINT}organization-goals`,
    generateTeamGoals: `${TYMELINE_TOOLS_ENDPOINT}organization-goals/{organization_goal_id}/team-goals/generate`,
    createTeamGoal: `${TYMELINE_TOOLS_ENDPOINT}goals/teams`,
    // getAllTeamGoals: `${TYMELINE_TOOLS_ENDPOINT}team-goals`,
    // teamGoal: `${TYMELINE_TOOLS_ENDPOINT}organization-goals/{organization_goal_id}/team-goals`,
    getAllteamMemberGoals: `${TYMELINE_TOOLS_ENDPOINT}team-member-goals`,
    teamMemberGoals: `${TYMELINE_TOOLS_ENDPOINT}organization-goals/{organization_goal_id}/team-goals/{team_goal_id}/team-member-goals`,
    // teamMemberGoalById: `${TYMELINE_TOOLS_ENDPOINT}organization-goals/{organization_goal_id}/team-goals/{team_goal_id}/team-member-goals/{team_member_goal_id}`,
    teamGoalsList: `${TYMELINE_TOOLS_ENDPOINT}goals/teams/`,
    generateLinkToken: `${TYMELINE_TOOLS_ENDPOINT}integrations/merge/link-token/generate`,
    savePublicToken: `${TYMELINE_TOOLS_ENDPOINT}integrations/merge/public-token/verify`,
    syncMerge: `${TYMELINE_TOOLS_ENDPOINT}integrations/{integration_id}/merge/sync`,
    revokeLicence: `${TYMELINE_TOOLS_ENDPOINT}licenses/revoke`,
    revokeTeamMember: `${TYMELINE_TOOLS_ENDPOINT}integrations/teams/members`,
    reassignLicense: `${TYMELINE_TOOLS_ENDPOINT}licenses/assign`,
    getTeamMembers: `${TYMELINE_TOOLS_ENDPOINT}integrations/merge/teams`,
    getNewTeamMembers: `${TYMELINE_TOOLS_ENDPOINT}integrations/{integration_id}/merge/teams`,
    getTeamMembersNew: `${TYMELINE_TOOLS_ENDPOINT}integrations/teams`,
    inviteUser: `${API_ENDPOINT}dbu/invite_user`,

    onboardTeams: `${API_ENDPOINT}tm/onboard/teams`,
    onboardTeamsNew: `${TYMELINE_TOOLS_ENDPOINT}integrations/teams/members`,
    deleteUser: `${TYMELINE_TOOLS_ENDPOINT}integrations/teams/members`,
    getTradeOffs: `${TYMELINE_TOOLS_ENDPOINT}organization-goals/{organization_goal_id}/tradeoffs`,
    unifiedSuccess: `${TYMELINE_TOOLS_ENDPOINT}integrations/unified/success`,
    unifiedFailure: `${TYMELINE_TOOLS_ENDPOINT}integrations/unified/failure`,
    integrationSuccess: `${TYMELINE_TOOLS_ENDPOINT}integrations/{integration_id}/integrate`,
    // integrationFailure: `${TYMELINE_TOOLS_ENDPOINT}integrations/{integration_id}/failure`,

    // new; for simplicity only added base url for the new integrations
    internalIntegrationBaseUrl: `${TYMELINE_TOOLS_ENDPOINT}integrations`,
    // new

    // ------------- integrations ---------------
    getIntegrationsList: `${API_ENDPOINT}cd/integrations/list`,
    getIntegratedUsers: `${TYMELINE_TOOLS_ENDPOINT}integrations/users`,
    getAllIntegrationTypes: `${TYMELINE_TOOLS_ENDPOINT}integrations/categories`,
    updateIntegratedUserLocationByAdmin: `${TYMELINE_TOOLS_ENDPOINT}integrations/users/{account_id}`,

    // ----- epic creation ---------------
    createEpic: `${TYMELINE_TOOLS_ENDPOINT}epics`,
    getEpics: `${TYMELINE_TOOLS_ENDPOINT}epics`,
    getEpic: `${TYMELINE_TOOLS_ENDPOINT}epics/{id}`,
    saveEpic: `${TYMELINE_TOOLS_ENDPOINT}epics/{id}`,
    deleteEpic: `${TYMELINE_TOOLS_ENDPOINT}epics/{id}`,

    // ----- story, tradeoffs and milestone generation ---------------
    generateStories: `${TYMELINE_TOOLS_ENDPOINT}epics/{id}/stories/generate`,
    generateTradeoffs: `${TYMELINE_TOOLS_ENDPOINT}epics/{id}/tradeoffs/generate`,
    generateMilestones: `${TYMELINE_TOOLS_ENDPOINT}epics/{id}/story-milestones/generate`,
    generateMilestonesStatus: `${TYMELINE_TOOLS_ENDPOINT}epics/{id}/story-milestones/generate-status`,

    getStories: `${TYMELINE_TOOLS_ENDPOINT}stories`,
    getStory: `${TYMELINE_TOOLS_ENDPOINT}stories/{id}`,
    getMilestones: `${TYMELINE_TOOLS_ENDPOINT}story-milestones`,
    getMilestone: `${TYMELINE_TOOLS_ENDPOINT}story-milestones/{id}`,

    // ------- files ---------------- //
    uploadFile: `${TYMELINE_TOOLS_ENDPOINT}projects/files`,
    removeFile: `${TYMELINE_TOOLS_ENDPOINT}projects/files/{id}`,
    uploadMultipleFiles: `${TYMELINE_TOOLS_ENDPOINT}projects/files/bulk`,

    // ------- project ---------------- //
    createProject: `${TYMELINE_TOOLS_ENDPOINT}projects`,
    getProjects: `${TYMELINE_TOOLS_ENDPOINT}projects`,
    getProject: `${TYMELINE_TOOLS_ENDPOINT}projects/{id}`,
    saveProject: `${TYMELINE_TOOLS_ENDPOINT}projects/{id}`,
    analyzeProject: `${TYMELINE_TOOLS_ENDPOINT}projects/{id}/overview/generate`,
    generateProjectEpics: `${TYMELINE_TOOLS_ENDPOINT}projects/{id}/epics/generate`,
    getGeneratedProjectEpics: `${TYMELINE_TOOLS_ENDPOINT}projects/{id}/epics/generate`,

    // ------- project recommendations ---------------- //
    getProjectRecommendations: `${TYMELINE_TOOLS_ENDPOINT}projects/{id}/recommendations`,

    // ------- project dashboard ---------------- //
    getProjectDashboards: `${TYMELINE_TOOLS_ENDPOINT}projects/{id}/dashboards`,
    getProjectDashboard: `${TYMELINE_TOOLS_ENDPOINT}projects/{project_id}/dashboards/{id}`,
    getProjectDashboardRiskOverview: `${TYMELINE_TOOLS_ENDPOINT}projects/{id}/dashboard/risk-overview`,
    getProjectDashboardTaskCompletions: `${TYMELINE_TOOLS_ENDPOINT}projects/{project_id}/dashboard/task-completions`,
    getProjectDashboardTeamEngagement: `${TYMELINE_TOOLS_ENDPOINT}projects/{project_id}/dashboard/team-engagements`,
    getProjectDashboardTeamWorkload: `${TYMELINE_TOOLS_ENDPOINT}projects/{project_id}/dashboard/team-member-workloads`,
    getProjectDashboardKPIs: `${TYMELINE_TOOLS_ENDPOINT}projects/{project_id}/dashboard/kpis`,
    getProjectDashboardBudgetBurnRate: `${TYMELINE_TOOLS_ENDPOINT}projects/{project_id}/dashboard/budget-burn-rate`,
    getProjectDashboardProjects: `${TYMELINE_TOOLS_ENDPOINT}projects/{project_id}/dashboard/project-timeline`,
    getProjectDashboardMilestones: `${TYMELINE_TOOLS_ENDPOINT}projects/{project_id}/dashboard/milestones`,

    // ------- project analysis / overview ---------------- //
    getProjectAnalysisGapAnalysis: `${TYMELINE_TOOLS_ENDPOINT}projects/{project_id}/overview/gap-analysis`,
    getProjectAnalysisRecommendations: `${TYMELINE_TOOLS_ENDPOINT}projects/{project_id}/overview/recommendations`,
    getProjectAnalysisSkills: `${TYMELINE_TOOLS_ENDPOINT}projects/{project_id}/overview/skills`,
    getProjectAnalysisSuccessRates: `${TYMELINE_TOOLS_ENDPOINT}projects/{project_id}/overview/success-rates`,
    getProjectAnalysisWorkLifeBalance: `${TYMELINE_TOOLS_ENDPOINT}projects/{project_id}/overview/work-life-balance`,

    // ------- talent dashboard ---------------- //
    getTalentRecommendations: `${TYMELINE_TOOLS_ENDPOINT}talents/recommendations`,
    getTalentDashboard: `${TYMELINE_TOOLS_ENDPOINT}talents/dashboards`,
    getEmployeeAttrition: `${TYMELINE_TOOLS_ENDPOINT}talents/dashboard/employee-attrition`,
    getTalentSkillsUtilization: `${TYMELINE_TOOLS_ENDPOINT}talents/dashboard/skill-utilizations`,
    getTalentPerformanceTrends: `${TYMELINE_TOOLS_ENDPOINT}talents/dashboard/performance-trends`,
    getTalentEmployeeEngagement: `${TYMELINE_TOOLS_ENDPOINT}talents/dashboard/employee-engagements`,
    getTalentTrainingImpact: `${TYMELINE_TOOLS_ENDPOINT}talents/dashboard/training-impacts`,
    getDemographics: `${TYMELINE_TOOLS_ENDPOINT}talents/dashboard/diversity-metrics`,
    getBurnoutRates: `${TYMELINE_TOOLS_ENDPOINT}talents/dashboard/burnouts`,
    getHiringForecast: `${TYMELINE_TOOLS_ENDPOINT}talents/dashboard/hiring-forecast`,

    //Chatbot
    getConversationId: `${TYMELINE_TOOLS_ENDPOINT}via-chatbot/conversations`,
    pushUserMessage: `${TYMELINE_TOOLS_ENDPOINT}via-chatbot/conversations/{conversation_id}/messages`,
    fetchMessageHistory: `${TYMELINE_TOOLS_ENDPOINT}via-chatbot/conversations/{conversation_id}/messages`,

    // ------- user dashboard ---------------- //
    getUserDashboardRecommendations: `${TYMELINE_TOOLS_ENDPOINT}users/dashboard/recommendations`,
};
