export const tabsStyles = (isSelected) => ({
    flex: "none",
    backgroundColor: "rgba(255, 255, 255, 0.65)",
    borderRadius: 0,
    "& .MuiTab-root": {
        flex: "0 1 auto",
        textAlign: "center",
        padding: "0.875rem 1rem",
    },
    "& .MuiTabs-indicator": {
        height: "2px",
        backgroundColor: "transparent",
        borderRadius: 0,
        boxShadow: "none",
    },
    "&.MuiTabs-root": {
        padding: "0 1rem",
    },
});

export const tabIndicatorProps = {
    style: {
        borderBottom: "2px solid",
        borderImage: "linear-gradient(91.51deg, #FF5733 0%, #FA9985 102.39%) 1",
    },
};

export const getTabStyles = (isSelected) => ({
    background: isSelected
        ? "linear-gradient(91.51deg, #FF5733 0%, #FA9985 102.39%)"
        : "none",
    WebkitBackgroundClip: isSelected ? "text" : "none",
    WebkitTextFillColor: isSelected ? "transparent" : "initial",
    textTransform: "none",
    "&:focus": {
        outline: "none",
    },
    "&.Mui-selected": {
        color: "#ff5733ff !important",
    },
    fontSize: "0.875rem",
    fontWeight: isSelected ? "bold" : "initial",
});
